<template>
  <el-dialog visible top="30vh" width="488px" custom-class="rounded" @close="$emit('closed')">
    <template #title>
      <div class="border-bottom pb-4">
        <h2>{{ $t('price.PriceListsManagement.priceListsTable.newPriceList') }}</h2>
      </div>
    </template>
    <el-form ref="form" :rules="formValidationRules" :model="model">
      <div class="row g-0">
        <div class="col-2 pt-2" :class="$direction === 'rtl' ? 'ms-2' : 'me-2'">
          {{ $t('price.PriceListsManagement.priceListsTable.priceListName') }}
        </div>
        <div class="col">
          <el-form-item prop="name">
            <el-input v-model="model.name" />
          </el-form-item>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-2 pt-2" :class="$direction === 'rtl' ? 'ms-2' : 'me-2'">
          {{ $t('price.PriceListsManagement.priceListsTable.description') }}
        </div>
        <div class="col">
          <el-form-item prop="description">
            <el-input v-model="model.description" type="textarea" :rows="3" :resize="'none'" />
          </el-form-item>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <Button type="secondary" @click="$emit('closed')">
          {{ $t('commons.cancel') }}
        </Button>
        <Button type="primary" @click="handleApplyButton">
          {{ $t('commons.apply') }}
        </Button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { ref, reactive, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core/components';

export default {
  components: { Button },
  setup(_, { emit }) {
    const root = getCurrentInstance().proxy;
    const model = reactive({});
    const form = ref(null);
    const formValidationRules = {
      name: [{ type: 'string', required: true, message: root.$i18n.t('commons.validationError.name') }],
      description: [{ type: 'string' }],
    };
    const handleApplyButton = () => {
      form.value.validate((valid) => {
        if (valid) {
          emit('submit', model);
        }
      });
    };
    return {
      model,
      form,
      formValidationRules,
      handleApplyButton,
    };
  },
};
</script>

<style lang="scss"></style>
