<template>
  <div>
    <el-form ref="form" :model="formModel" :show-message="false" class="mb-2">
      <Table
        :data="productsInCurrentPage"
        :columns="columns"
        show-index
        border
        rounded
        :hover="false"
        @focusin.native="handleFocus"
        @paste.native="handlePaste"
      >
        <template #cell-gtin="{ rowIndex }">
          <el-form-item :prop="`tableData.${rowIndex}.gtin`">
            <el-input v-model="productsInCurrentPage[rowIndex].gtin" @change="updateProductId(rowIndex)" />
          </el-form-item>
        </template>
        <template #cell-sku="{ rowIndex }">
          <el-form-item :prop="`tableData.${rowIndex}.sku`">
            <el-input v-model="productsInCurrentPage[rowIndex].sku" @change="updateProductId(rowIndex)" />
          </el-form-item>
        </template>
        <template #cell-name="{ rowIndex }">
          <el-form-item :prop="`tableData.${rowIndex}.name`" required>
            <el-input v-model="productsInCurrentPage[rowIndex].name" @change="updateProductId(rowIndex)" />
          </el-form-item>
        </template>
        <template #cell-uom="{ rowData: { productId } }">
          {{ getUOM(productId) ? $t(`product.units.${getUOM(productId)}`) : '-' }}
        </template>
        <template #cell-currentPrice="{ rowData: { productId } }">
          {{ formatMoney(getCurrentPrice(productId)) }}
        </template>
        <template #cell-updatePrice="{ rowIndex }">
          <el-form-item :prop="`tableData.${rowIndex}.updatePrice`" required>
            <InputMoney v-model="productsInCurrentPage[rowIndex].updatePrice" style="width: 10em" />
          </el-form-item>
        </template>
        <template #cell-deleteRowButton="{ rowIndex }">
          <div class="delete-wrapper">
            <Button type="danger" @click="() => deleteTableRow(rowIndex)">{{
              $t('price.priceListUpdate.pricesTable.deleteRowButton')
            }}</Button>
          </div>
        </template>
        <template #cell-index="{ rowIndex }">
          <el-form-item :prop="`tableData.${rowIndex}.productId`" required>
            <template v-if="!productsInCurrentPage[rowIndex].productId">
              <WarningIcon width="24" height="24" class="text-danger" />
            </template>
            <template v-else>{{ rowIndex + 1 }}</template>
          </el-form-item>
        </template>
        <template #header-kebab="{ column: { dropdown } }">
          <el-dropdown trigger="click" placement="left" @command="createProducts">
            <Button type="text" class="p-0 text-typography-primary" @click.stop>
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item :disabled="isEmpty(productsToCreate)"><PlusIcon />{{ dropdown }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </Table>
    </el-form>
    <div class="w-100">
      <div class="d-flex justify-content-between w-100 mt-2">
        <Button @click="addNewTableRow"><PlusIcon />{{ $t('row') }}</Button>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :total="formModel.tableData.length"
          :page-size="pageSize"
          :current-page.sync="currentPage"
        />
        <div :style="{ width: '83px' }"></div>
      </div>
    </div>
    <CreateProductModal
      v-if="createProductModalOpen"
      :product-checks="productChecks"
      @resolved="handleSwitches"
      @close="createProductModalOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { isEmpty, pipe, pick, filter, identity } from 'ramda';
import leven from 'leven';

import { Table, Button, InputMoney } from '@/modules/core';
import { WarningIcon, KebabIcon, PlusIcon } from '@/assets/icons';
import i18n from '@/imports/startup/client/i18n';
import { options } from '@/locale/dateConfig';
import { currency } from '@/locale/numberConfig';
import { useProductCreate, useProductPatch } from '@/modules/product';

import { DECISIONS, default as CreateProductModal } from './CreateProductModal';

const formatDate = (ms) => {
  return ms ? new Date(ms).toLocaleDateString(i18n.locale, options.short) : i18n.t('commons.unknownDate');
};

const cleanProductToCreate = pipe(pick(['name', 'sku', 'gtin']), filter(identity));

const formatMoney = (number) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number / 100).toLocaleString(i18n.locale, currency)
    : '-';
};

const isEqualRef = (ref1, ref2) => ref1.name === ref2.name && (ref1.sku ? ref1.sku === ref2.sku : !ref2.sku);

const areRefsPossiblySameProduct = (ref1, ref2) =>
  (getScore(ref1.name, ref2.name) <= 0.3 && (!ref1.sku || !ref2.sku || ref1.sku === ref2.sku)) ||
  ref1.name === ref2.name;

const getScore = (text1, text2) => leven(text1, text2) / Math.max(text1.length, text2.length);

const TABLE_HEADERS = {
  GTIN: 'gtin',
  SKU: 'sku',
  NAME: 'name',
  UOM: 'uom',
  CURRENT_PRICE: 'currentPrice',
  UPDATE_PRICE: 'updatePrice',
  DELETE_ROW_BUTTON: 'deleteRowButton',
};

export default {
  components: { Button, Table, WarningIcon, InputMoney, KebabIcon, PlusIcon, CreateProductModal },
  props: {
    updateDate: { type: Date, required: true },
    products: { type: Array, default: () => [] },
    prices: { type: Array, default: () => [] },
    businessId: { type: String, required: true },
  },
  setup() {
    const { mutate: createProduct } = useProductCreate();
    const { mutate: patchProduct } = useProductPatch();

    const formModel = ref({
      tableData: [],
    });
    return {
      formModel,
      formatDate,
      formatMoney,
      createProductModalOpen: ref(false),
      isEmpty,
      createProduct,
      patchProduct,
      pageSize: 20,
      currentPageIndex: ref(0),
    };
  },
  computed: {
    productsToCreate() {
      return this.formModel.tableData.filter((data) => !data.productId && data.name);
    },
    productChecks() {
      return this.productsToCreate
        .map((productToCreate) => {
          const similarProducts = this.products
            .map((product) => {
              return {
                product,
                [DECISIONS.RECORDING_TYPO]: product.references.some(
                  (ref) => !isEqualRef(ref, productToCreate) && getScore(ref.name, productToCreate.name) <= 0.3
                ),
                [DECISIONS.ADDITIONAL_REFERENCE]:
                  (!productToCreate.gtin || productToCreate.gtin === product.gtin) &&
                  product.references.some((ref) => areRefsPossiblySameProduct(ref, productToCreate)),
                [DECISIONS.MISSING_GTIN]:
                  !product.gtin &&
                  productToCreate.gtin &&
                  product.references.some((ref) => isEqualRef(ref, productToCreate)),
                [DECISIONS.ADDITIONAL_REFERENCE_AND_MISSING_GTIN]:
                  !product.gtin &&
                  productToCreate.gtin &&
                  product.references.some((ref) => areRefsPossiblySameProduct(ref, productToCreate)) &&
                  product.references.every((ref) => !isEqualRef(ref, productToCreate)),
              };
            })
            .filter((productCheck) => Object.values(DECISIONS).some((decision) => productCheck[decision]));
          return {
            productToCreate,
            similarProducts,
          };
        })
        .filter(({ similarProducts }) => similarProducts.length);
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
    pageStartingIndex() {
      return this.currentPageIndex * this.pageSize;
    },
    productsInCurrentPage() {
      return this.formModel.tableData.slice(
        this.currentPageIndex * this.pageSize,
        this.currentPageIndex * this.pageSize + this.pageSize
      );
    },
    columns() {
      return [
        {
          key: TABLE_HEADERS.GTIN,
          header: this.$t('price.priceListUpdate.pricesTable.headers.gtin'),
          width: '12%',
        },
        {
          key: TABLE_HEADERS.SKU,
          header: this.$t('price.priceListUpdate.pricesTable.headers.sku'),
          width: '12%',
        },
        {
          key: TABLE_HEADERS.NAME,
          header: this.$t('price.priceListUpdate.pricesTable.headers.productName'),
          width: '29%',
        },
        {
          key: TABLE_HEADERS.UOM,
          header: this.$t('price.priceListUpdate.pricesTable.headers.uom'),
          width: '10%',
        },
        {
          key: TABLE_HEADERS.CURRENT_PRICE,
          header: this.$t('price.priceListUpdate.pricesTable.headers.currentPrice'),
          width: '10%',
        },
        {
          key: TABLE_HEADERS.UPDATE_PRICE,
          header: this.$t('price.priceListUpdate.pricesTable.headers.updateInX', {
            date: this.formatDate(this.updateDate),
          }),
          width: '15%',
        },
        {
          key: TABLE_HEADERS.DELETE_ROW_BUTTON,
          width: '11%',
        },
        {
          key: 'kebab',
          dropdown: this.$tc('price.priceListUpdate.pricesTable.createProduct', this.productsToCreate.length, {
            count: this.productsToCreate.length,
          }),
          width: '60px',
        },
      ];
    },
  },
  methods: {
    getUOM(productId) {
      return this.products.find((product) => product.id === productId)?.uom;
    },
    getCurrentPrice(productId) {
      const price = this.prices.find((price) => price.productId === productId);
      return price?.data.reduce((last, priceInfo) => (priceInfo.date > last.date ? priceInfo : last)).price;
    },
    submitForm() {
      this.$refs.form.validate((valid, errors) => {
        const errorRows = Object.keys(errors || {}).map((errKey) => parseInt(errKey.split('.')[1]));
        const errorCount = [...new Set(errorRows)].reduce((sum) => sum + 1, 0);
        const data = this.formModel.tableData.map((row) => ({
          price: row[TABLE_HEADERS.UPDATE_PRICE],
          productId: row.productId,
        }));
        this.$emit('formSubmitted', { data: valid ? data : null, errorCount });
      });
    },
    addNewTableRow() {
      this.formModel.tableData.push({});
    },
    handleFocus(event) {
      const trElement = event.path.find((element) => element.nodeName === 'TR');
      const tdElement = event.path.find((element) => element.nodeName === 'TD');
      if (!tdElement || !trElement) return;
      this.focusedField = { rowIndex: trElement.rowIndex - 1, columnIndex: tdElement.cellIndex - 1 };
    },
    handlePaste(event) {
      const textDataFromClipboard = event.clipboardData.getData('text/plain');

      if (!textDataFromClipboard) return;

      const splitDataFromClipboard = textDataFromClipboard.replace(/(\r)/gm, '').split('\n');
      this.pasteToFocusedField(splitDataFromClipboard);

      event.preventDefault();
    },
    pasteToFocusedField(data = []) {
      if (!this.focusedField) return;
      const { rowIndex, columnIndex } = this.focusedField;
      const fieldToUpdate = this.columns[columnIndex].key;
      data.forEach((data, index) => {
        const parsedData = fieldToUpdate === TABLE_HEADERS.UPDATE_PRICE ? this.parseNumberWithComma(data) * 100 : data;
        const tableRow = this.formModel.tableData[rowIndex + index];
        if (!tableRow) this.formModel.tableData.push({ [fieldToUpdate]: parsedData });
        else this.$set(tableRow, fieldToUpdate, parsedData);
        this.updateProductId(rowIndex + index);
      });
    },
    updateProductId(rowIndex) {
      const product = this.products.find((product) => {
        return (
          (this.formModel.tableData[rowIndex].gtin ? product.gtin === this.formModel.tableData[rowIndex].gtin : true) &&
          product.references.some(
            (reference) =>
              reference.name === this.formModel.tableData[rowIndex].name &&
              (reference.sku
                ? reference.sku === this.formModel.tableData[rowIndex].sku
                : !this.formModel.tableData[rowIndex].sku)
          )
        );
      });
      this.$set(this.formModel.tableData[rowIndex], 'productId', product?.id);
    },
    deleteTableRow(index) {
      this.formModel.tableData.splice(index, 1);
    },
    parseNumberWithComma(str) {
      return Number.parseFloat(str.replace(',', ''));
    },
    createProducts() {
      if (this.productChecks.length) this.createProductModalOpen = true;
      else {
        const loading = this.$loading();
        this.createProductsAndUpdateRows()
          .catch(() => this.$message.error(this.$t('errors.action')))
          .finally(() => loading.close());
      }
    },
    updateRowWithProduct(rowIndex, product, updateRef = false) {
      const row = this.formModel.tableData[rowIndex];
      this.$set(row, 'productId', product.id);
      if (updateRef) {
        this.$set(row, 'gtin', product.gtin);
        this.$set(row, 'sku', product.references[0].sku);
        this.$set(row, 'name', product.references[0].name);
      }
    },
    async handleSwitches(switches) {
      this.createProductModalOpen = false;
      const loading = this.$loading();
      try {
        const productUpdates = [];
        switches.forEach(({ decision, productId, productCheckIndex }) => {
          const product = this.products.find(({ id }) => id === productId);
          const rowIndex = this.formModel.tableData.indexOf(this.productChecks[productCheckIndex].productToCreate);
          const productToCreate = this.productChecks[productCheckIndex].productToCreate;
          switch (decision) {
            case DECISIONS.RECORDING_TYPO:
              this.updateRowWithProduct(rowIndex, product, true);
              break;
            case DECISIONS.ADDITIONAL_REFERENCE:
              productUpdates.push(
                this.patchProduct({
                  id: productId,
                  productData: { reference: { name: productToCreate.name, sku: productToCreate.sku } },
                }).then(({ data: { productPatch } }) => this.updateRowWithProduct(rowIndex, productPatch))
              );
              break;
            case DECISIONS.MISSING_GTIN:
              productUpdates.push(
                this.patchProduct({ id: productId, productData: { gtin: productToCreate.gtin } }).then(
                  ({ data: { productPatch } }) => this.updateRowWithProduct(rowIndex, productPatch)
                )
              );
              break;
            case DECISIONS.ADDITIONAL_REFERENCE_AND_MISSING_GTIN:
              productUpdates.push(
                this.patchProduct({
                  id: productId,
                  productData: {
                    gtin: productToCreate.gtin,
                    reference: { name: productToCreate.name, sku: productToCreate.sku },
                  },
                }).then(({ data: { productPatch } }) => this.updateRowWithProduct(rowIndex, productPatch))
              );
              break;
          }
        });
        const results = await Promise.allSettled(productUpdates);
        if (results.some(({ status }) => status === 'rejected')) throw new Error();
        await this.$nextTick();
        await this.createProductsAndUpdateRows();
      } catch {
        this.$message.error(this.$t('errors.action'));
      } finally {
        loading.close();
      }
      await this.$emit('productRefetch');
    },
    createProductsAndUpdateRows() {
      return Promise.allSettled(
        this.productsToCreate.map((productToCreate) =>
          this.createProduct({
            productData: { ...cleanProductToCreate(productToCreate), businessId: this.businessId },
          }).then(({ data: { productCreate } }) =>
            this.updateRowWithProduct(this.formModel.tableData.indexOf(productToCreate), productCreate)
          )
        )
      )
        .then((results) => {
          if (results.some(({ status }) => status === 'rejected')) throw new Error();
        })
        .catch((err) => console.log('err', err));
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 0;
}

.delete-wrapper {
  visibility: hidden;
}

::v-deep tr:hover .delete-wrapper {
  visibility: visible;
}
::v-deep td {
  padding: 0.25rem;
  vertical-align: middle;
}
::v-deep th {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
::v-deep th:first-child,
::v-deep td:first-child {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>
