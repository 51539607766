<template>
  <el-dialog visible custom-class="product-create-modal" @close="$emit('close')">
    <template #title>
      <h2 class="border-bottom pb-4">
        {{
          $t('price.priceListUpdate.pricesTable.createProductModal.title', {
            index: index + 1,
            count: productChecks.length,
          })
        }}
      </h2>
    </template>
    <div class="alert">
      <NoticeIcon class="text-warning" />
      {{ $tc('price.priceListUpdate.pricesTable.createProductModal.alertDescription', similarProducts.length) }}
    </div>

    <div class="title">
      {{ $t('price.priceListUpdate.pricesTable.createProductModal.recordedProduct') }}
    </div>
    <table class="table">
      <thead>
        <tr>
          <th width="20%">{{ $t('price.priceListUpdate.pricesTable.createProductModal.gtin') }}</th>
          <th width="20%">{{ $t('price.priceListUpdate.pricesTable.createProductModal.sku') }}</th>
          <th width="30%">{{ $t('price.priceListUpdate.pricesTable.createProductModal.name') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ currentProduct.gtin }}</td>
          <td>{{ currentProduct.sku }}</td>
          <td>{{ currentProduct.name }}</td>
          <td class="button-cell">
            <div class="button-wrapper">
              <Button type="text" :disabled="!!sameNameExist" @click="nextCheck"
                ><PlusIcon />{{
                  $t('price.priceListUpdate.pricesTable.createProductModal.options.createNewProduct')
                }}</Button
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="title mt-5">
      {{ $tc('price.priceListUpdate.pricesTable.createProductModal.existedProduct', similarProducts.length) }}
    </div>
    <table class="table">
      <thead>
        <tr>
          <th width="20%">{{ $t('price.priceListUpdate.pricesTable.createProductModal.gtin') }}</th>
          <th width="20%">{{ $t('price.priceListUpdate.pricesTable.createProductModal.sku') }}</th>
          <th width="30%">{{ $t('price.priceListUpdate.pricesTable.createProductModal.name') }}</th>
          <th width="15%">{{ $t('price.priceListUpdate.pricesTable.createProductModal.createdAt') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="similarProduct in similarProducts" :key="similarProduct.product.id">
          <td>{{ similarProduct.product.gtin }}</td>
          <td>{{ similarProduct.product.references[0].sku }}</td>
          <td>{{ similarProduct.product.references[0].name }}</td>
          <td>{{ formatDate(similarProduct.product.createdAt) }}</td>
          <td class="button-cell">
            <el-dropdown
              class="button-wrapper"
              trigger="click"
              placement="bottom"
              @command="(command) => switchProduct(command, similarProduct.product)"
            >
              <Button type="text" @click.stop>
                {{ $t('price.priceListUpdate.pricesTable.createProductModal.options.switchProduct') }}
              </Button>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="decision in DECISIONS"
                  :key="decision"
                  :command="decision"
                  :disabled="!similarProduct[decision]"
                  >{{
                    $t(`price.priceListUpdate.pricesTable.createProductModal.options.${decision}`)
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';

import { options } from '@/locale/dateConfig';
import { NoticeIcon, PlusIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

export const DECISIONS = {
  RECORDING_TYPO: 'recordingTypo',
  ADDITIONAL_REFERENCE: 'additionalReference',
  MISSING_GTIN: 'missingGTIN',
  ADDITIONAL_REFERENCE_AND_MISSING_GTIN: 'additionalReferenceAndMissingGTIN',
};

export default {
  components: { Button, NoticeIcon, PlusIcon },
  props: {
    productChecks: { type: Array, required: true },
  },
  setup() {
    return {
      index: ref(0),
      switches: [],
    };
  },
  computed: {
    currentProduct() {
      return this.productChecks[this.index].productToCreate;
    },
    similarProducts() {
      return this.productChecks[this.index].similarProducts;
    },
    DECISIONS: () => DECISIONS,
    sameNameExist() {
      return this.similarProducts.find((similarProduct) => similarProduct.product.name === this.currentProduct.name);
    },
  },
  methods: {
    nextCheck() {
      if (this.index < this.productChecks.length - 1) this.index += 1;
      else this.$emit('resolved', this.switches);
    },
    switchProduct(command, product) {
      this.switches.push({ decision: command, productId: product.id, productCheckIndex: this.index });
      this.nextCheck();
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.alert {
  border-color: $warning;
  background: change-color($color: $warning, $lightness: 98%);
  color: $typography-primary;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: $typography-primary;
}

.button-cell {
  padding: 0.25rem;

  .button-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
}
::v-deep .product-create-modal {
  .el-dialog__body {
    padding: 10px 20px 30px 20px;
  }
}
</style>
